import 'core-js/features/promise';
import { Deferred } from '@phaier/util/lib/Deferred';

import { Page } from '#/views/libs/pages';
import { Video } from '#/views/components/video/Video';

import './VideoPage.scss';

export interface VideoPageOptions {
  readonly dash: string;
  readonly hls: string;
}

export function createVideoPage(options: VideoPageOptions): Page {
  const $elem = $(`
    <div id="video-area" class="VideoPage hidden">
      <video controls style="width: 100%; height: 100%" preload="metadata"></video>
    </div>
  `);
  $('body').append($elem);

  const element = $elem.find('video')[0] as HTMLVideoElement;
  const video: Video = new Video(element);

  const unmounted = new Deferred<void>();
  const unmount = () => {
    $elem.remove();

    unmounted.resolve();
  };

  return {
    show: async () => {
      $elem.removeClass('hidden');

      await video.play({
        dash: options.dash,
        hls: options.hls,
      });

      unmount();
    },
    hide: () => {
      $elem.addClass('hidden');
    },
    unmount: () => {
      video.stop();
    },
    unmounted: () => {
      return unmounted.promise();
    },
    onKeydown: (e) => {
      switch (e.which) {
        default:
          break;
      }
    },
  };
}
