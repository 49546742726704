export interface VideoInfo {
  /**
   * ID
   */
  readonly id: number;

  /**
   * サムネイル画像の URL
   */
  readonly thumbnailUrl: string;

  /**
   * MPEG-DASH 形式の動画の URL
   */
  readonly dashUrl: string;

  /**
   * HLS 形式の動画の URL
   */
  readonly hlsUrl: string;

  readonly live: boolean;

  /**
   * 動画のタイトル
   */
  readonly title: string;

  /**
   * 動画の詳細
   */
  readonly description: string;
}

export namespace VideoInfo {
  export const animals: ReadonlyArray<VideoInfo> = [
    {
      id: 1,
      thumbnailUrl: './image/vod/thumbnails/vlcsnap-2023-11-21-17h56m51s971.png',
      dashUrl: 'https://video.imari.miharu-demo.com/dash/5073.mpd',
      hlsUrl: 'https://video.imari.miharu-demo.com/hls/5073.m3u8',
      live: false,
      title: '第29回 伊万里っ子・キッズスポーツフェスティバル',
      description: 'ミハル通信ブース内に設置したライブカメラの映像をお送りいたします。',
    },
    {
      id: 2,
      thumbnailUrl: './image/vod/thumbnails/vlcsnap-2023-11-27-18h45m40s841.png',
      dashUrl: 'https://video.imari.miharu-demo.com/sora/dash/sora.mpd',
      hlsUrl: 'https://video.imari.miharu-demo.com/sora/hls/sora.m3u8',
      live: false,
      title: '空 (数秒)',
      description: '古河電気工業ブース内に設置したライブカメラの映像をお送りいたします。',
    },
    /*
    {
      id: 3,
      thumbnailUrl: './image/vod/thumbnails/vlcsnap-2023-11-27-18h46m39s800.png',
      dashUrl: 'https://video.imari.miharu-demo.com/sukiyaki/dash/sukiyaki.mpd',
      hlsUrl: 'https://video.imari.miharu-demo.com/sukiyaki/hls/sukiyaki.m3u8',
      live: false,
      title: 'すき焼き (数秒)',
      description:
        '鎌倉工場からのライブストリーミングが視聴できます。工場内に設置された鎌倉の模型の様子がライブ配信されます。',
    },
    */

    {
      id: 4,
      thumbnailUrl: './image/vod/thumbnails/M1000026.jpg',
      dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000026/dash/M1000026.mpd',
      hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000026/hls/M1000026.m3u8',
      live: false,
      title: '飛び立った後',
      description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
    },
    {
      id: 5,
      thumbnailUrl: './image/vod/thumbnails/M1000028.jpg',
      dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000028/dash/M1000028.mpd',
      hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000028/hls/M1000028.m3u8',
      live: false,
      title: 'アオサギ',
      description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
    },
    {
      id: 6,
      thumbnailUrl: './image/vod/thumbnails/M1000031.jpg',
      dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000031/dash/M1000031.mpd',
      hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000031/hls/M1000031.m3u8',
      live: false,
      title: '鯉の群れ',
      description: '箱根の山道をバイクで駆け抜ける前面展望映像です。',
    },

    {
      id: 7,
      thumbnailUrl: './image/vod/thumbnails/M1000007.jpg',
      dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000007/dash/M1000007.mpd',
      hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000007/hls/M1000007.m3u8',
      live: false,
      title: '白い鳥',
      description: 'ミハル通信ブース内に設置したライブカメラの映像をお送りいたします。',
    },
    {
      id: 8,
      thumbnailUrl: './image/vod/thumbnails/M1000014.jpg',
      dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000014/dash/M1000014.mpd',
      hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000014/hls/M1000014.m3u8',
      live: false,
      title: '水鳥達',
      description: '古河電気工業ブース内に設置したライブカメラの映像をお送りいたします。',
    },
    {
      id: 9,
      thumbnailUrl: './image/vod/thumbnails/M1000025.jpg',
      dashUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000025/dash/M1000025.mpd',
      hlsUrl: 'https://video.cts-2023.miharu-demo.com/vod-animals/M1000025/hls/M1000025.m3u8',
      live: false,
      title: 'サギ',
      description:
        '鎌倉工場からのライブストリーミングが視聴できます。工場内に設置された鎌倉の模型の様子がライブ配信されます。',
    },
  ];
}
