import template from './RootPageToolbar.html';
import './RootPageToolbar.scss';

const $template = $(template);

export function createRootPageToolbar(): JQuery {
  const $elem = $template.clone();

  return $elem;
}
