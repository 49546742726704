import { Page, openPage } from '#/views/libs/pages';
import { createCatvInfoPage } from '#/views/index/catv/CatvInfoPage';
import { createCityInfoPage } from '#/views/index/city/CityInfoPage';
import { createRootPageToolbar } from '#/views/components/RootPageToolbar';
import { createMultiLineThumbnailSelector } from '#/views/components/thumbnails/MultiLineThumbnailSelector';
import { createGikaiInfoPage } from '#/views/index/gikai/GikaiInfoPage';

import template from './AreaSelectorPage.html';
import { createAreaDetailPage } from '#/views/index/areas/AreaDetailPage';

export interface AreaSelectorPageOptions {}

export function createAreaSelectorPage(options: AreaSelectorPageOptions): Page {
  const $elem = $(template);

  const areas = [
    ['伊万里', `./image/locations/IMG_5126.jpg`],
    ['牧島', `./image/locations/IMG_1282.jpg`],
    ['立花', `./image/locations/IMG_1129.jpg`],
    ['大坪', `./image/locations/IMG_6752.jpg`],
    ['黒川', `./image/locations/IMG_6304.jpg`],
    ['波多津', `./image/locations/IMG_2223.jpg`],

    ['南波多', `./image/locations/IMG_6387.jpg`],
    ['大川', `./image/locations/IMG_5147.jpg`],
    ['大川内', `./image/locations/IMG_2136.jpg`],
    ['松浦', `./image/locations/IMG_6691.jpg`],
    ['二里', `./image/locations/IMG_1076.jpg`],
    ['東山代', `./image/locations/IMG_5154.jpg`],

    ['山代', `./image/locations/IMG_2215.jpg`],
  ];
  const $rows = createMultiLineThumbnailSelector(areas, {
    aspectRatio: '16by9',
    setThumbnail($thumbnail, [area, image]) {
      $thumbnail.find('h3').text(area);
      $thumbnail.find('img').attr('src', image);
    },
    onFocused($e, [area]) {},
    onClicked($e, [area]) {
      openPage(createAreaDetailPage({ area }));
    },
    colClass: 'col-md-2',
    cols: 6,
  });

  const showBlue = () => {
    openPage(createCityInfoPage({}));
  };

  const showRed = () => {};

  const showGreen = () => {
    openPage(createGikaiInfoPage({}));
  };

  const showYellow = () => {
    openPage(createCatvInfoPage({}));
  };

  const $toolbar = createRootPageToolbar();
  $toolbar.find('.btn-primary').on('click', (e) => {
    showBlue();
  });
  $toolbar.find('.btn-danger').on('click', (e) => {
    showRed();
  });
  $toolbar.find('.btn-success').on('click', (e) => {
    showGreen();
  });
  $toolbar.find('.btn-warning').on('click', (e) => {
    showYellow();
  });

  $elem
    .find('.row')
    .last()
    .after(...$rows);

  $('body').append($toolbar);
  $('body').append($elem);

  return {
    show: () => {
      $toolbar.show();
      $elem.show();
    },
    hide: () => {
      $toolbar.hide();
      $elem.hide();
    },
    unmount: () => {
      $toolbar.remove();
      $elem.remove();
    },
    onKeydown: (e) => {
      switch (e.which) {
        case VK_BLUE:
          showBlue();
          break;
        case VK_RED:
          showRed();
          break;
        case VK_GREEN:
          showGreen();
          break;
        case VK_YELLOW:
          showYellow();
          break;
        default:
          break;
      }
    },
  };
}
