import 'core-js/features/string/pad-start';

import { PdfDocument } from '#/views/components/pdf/PdfDocument';
import { PdfPage } from '#/views/components/pdf/PdfPage';

export interface Doc {
  readonly id: string;

  readonly title: string;

  readonly pages: number;
}

export namespace Doc {
  export const myude: ReadonlyArray<Doc> = [
    {
      id: 'myude/2024/01',
      title: 'iTV 番組案内 みゅうで！ 2024年01月',
      pages: 6,
    },
    {
      id: 'myude/2023/12',
      title: 'iTV 番組案内 みゅうで！ 2023年12月',
      pages: 6,
    },
    {
      id: 'myude/2023/11',
      title: 'iTV 番組案内 みゅうで！ 2023年11月',
      pages: 6,
    },
    {
      id: 'myude/2023/10',
      title: 'iTV 番組案内 みゅうで！ 2023年10月',
      pages: 6,
    },
  ];

  export const imari: ReadonlyArray<Doc> = [
    {
      id: 'imari/2024/01',
      title: 'iMAR! 広報伊万里 令和6年1月号',
      pages: 24,
    },
    {
      id: 'imari/2023/12',
      title: 'iMAR! 広報伊万里 令和5年12月号',
      pages: 22,
    },
  ];

  export const relations: ReadonlyArray<Doc> = [
    {
      id: '2023-10-2',
      title: 'iTV 番組案内 みゅうで！ 2023年10月',
      pages: 6,
    },
    {
      id: '2023-11-2',
      title: 'iTV 番組案内 みゅうで！ 2023年11月',
      pages: 6,
    },
    {
      id: 'imari-r0510',
      title: 'No.836　令和5年10月号 ●特集1　も～っと知ってほしい　伊万里梨・伊万里牛',
      pages: 4,
    },
  ];

  export function toPdfDocument(relation: Doc): PdfDocument {
    const pages: PdfPage[] = [];

    for (let i = 1; i <= relation.pages; i++) {
      const filename = i.toString().padStart(3, '0');
      pages.push({
        x01ImageUrl: `/image/docs/${relation.id}/x01/${filename}.jpg`,
        x02ImageUrl: `/image/docs/${relation.id}/x02/${filename}.jpg`,
        x04ImageUrl: `/image/docs/${relation.id}/x04/${filename}.jpg`,
      });
    }

    return {
      title: relation.title,
      thumbnailUrl: `/image/docs/${relation.id}/thumbnail.jpg`,
      pages,
    };
  }
}
