import 'core-js/features/promise';
import { openPage } from '#/views/libs/pages';
import { createCityInfoPage } from '#/views/index/city/CityInfoPage';

import './index.scss';

DashTVPlayer.LogHandler.log = (str, color) => {
  // console.log('[H-LOG]' + str);
};

DashTVPlayer.Debug.log = (msg) => {
  // console.log('[LOG  ] ' + msg);
};
DashTVPlayer.Debug.debug = (msg) => {
  // console.log('[DEBUG] ' + msg);
};
DashTVPlayer.Debug.info = (msg) => {
  // console.log('[INFO ] ' + msg);
};
DashTVPlayer.Debug.warn = (msg) => {
  // console.log('[WARN ] ' + msg);
};
DashTVPlayer.Debug.error = (msg) => {
  // console.log('[ERROR] ' + msg);
};

openPage(createCityInfoPage({}));
