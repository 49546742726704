import { inHybridcast } from '#/utils/hybridcast';
import { $moveLeftIcon } from '#/views/components/icon/moveLeftIcon';
import { $moveRightIcon } from '#/views/components/icon/moveRightIcon';

import template from './OneLineThumbnailSelector.html';
import './OneLineThumbnailSelector.scss';

const $buttonOriginal = $(template);

export interface OneLineThumbnailSelectorOptions<T> {
  readonly aspectRatio: '16by9' | '4by3' | 'auto';
  setThumbnail($thumbnail: JQuery, item: T): void;
  onFocused($thumbnail: JQuery, item: T): void;
  onClicked($thumbnail: JQuery, item: T): void;
}

function initThumbnail<T>(options: OneLineThumbnailSelectorOptions<T>): JQuery {
  const $button = $buttonOriginal.clone();

  if (options.aspectRatio === '16by9') {
    $button.find('.embed-responsive').addClass('embed-responsive-16by9');
  } else if (options.aspectRatio === '4by3') {
    $button.find('.embed-responsive').addClass('embed-responsive-4by3');
  } else {
    $button.find('.embed-responsive').after($(`<img alt="..." />`));
    $button.find('.embed-responsive').remove();
  }

  return $button;
}

export function createOneLineThumbnailSelector<T>(
  items: ReadonlyArray<T>,
  options: OneLineThumbnailSelectorOptions<T>
): JQuery {
  let offset = 0;

  const $thumbnailOriginal = initThumbnail(options);

  const $row = $(`<div class="row" />`);

  const $col = $('<div class="col-md-1"></div>');

  const $colLeft = $col.clone();
  $row.append($colLeft);
  const $moveLeftButton = $(`<button type="button" class="OneLineThumbnailSelector__moveLeft" />`);
  $moveLeftButton.append($moveLeftIcon.clone());

  $moveLeftButton.on('click', (e) => {
    e.preventDefault();
    moveLeft();
  });
  $moveLeftButton.on('focus', (e) => {
    if (inHybridcast()) {
      moveLeft();
      $thumbnails[$thumbnails.length - 1].trigger('focus');
    }
  });
  $colLeft.append($moveLeftButton);

  const $thumbnails = [0, 1, 2, 3, 4].map((i) => {
    const $thumbnail = $thumbnailOriginal.clone();

    $thumbnail.on('click', (e) => {
      e.preventDefault();

      options.onClicked($thumbnail, items[offset + i]);
    });

    $thumbnail.on('focus', (e) => {
      options.onFocused($thumbnail, items[offset + i]);
    });

    return $thumbnail;
  });

  $row.append(
    ...$thumbnails.map(($thumbnail) => {
      const $col = $(`<div class="col-md-2"></div>`);
      $col.append($thumbnail);
      return $col;
    })
  );

  const $colRight = $col.clone();
  $row.append($colRight);
  const $moveRightButton = $(`<button type="button" class="OneLineThumbnailSelector__moveRight"></button>`);
  $moveRightButton.append($moveRightIcon.clone());

  $moveRightButton.on('click', (e) => {
    e.preventDefault();
    moveRight();
  });
  $moveRightButton.on('focus', (e) => {
    if (inHybridcast()) {
      moveRight();
      $thumbnails[0].trigger('focus');
    }
  });

  $colRight.append($moveRightButton);

  const canMoveLeft = (): boolean => {
    return offset > 0;
  };

  const moveLeft = (): void => {
    if (offset === 0) {
      return;
    }

    offset = Math.max(0, offset - 5);
    setThumbnails();
    setMoveable();
  };

  const canMoveRight = (): boolean => {
    return offset < items.length - 5;
  };

  const moveRight = (): void => {
    if (offset >= items.length - 5) {
      return;
    }

    offset = Math.min(items.length - 5, offset + 5);
    setThumbnails();
    setMoveable();
  };

  const setThumbnails = (): void => {
    for (let i = 0; i < 5; i++) {
      const $thumbnail = $thumbnails[i];
      const item = items[offset + i];

      options.setThumbnail($thumbnail, item);
    }
  };

  const setMoveable = (): void => {
    if (canMoveLeft()) {
      $moveLeftButton.removeAttr('disabled');
    } else {
      $moveLeftButton.attr('disabled', 'disabled');
    }

    if (canMoveRight()) {
      $moveRightButton.removeAttr('disabled');
    } else {
      $moveRightButton.attr('disabled', 'disabled');
    }
  };

  setThumbnails();
  setMoveable();

  return $row;
}
