import { Page, openPage } from '#/views/libs/pages';
import { createCatvInfoPage } from '#/views/index/catv/CatvInfoPage';
import { createCityInfoPage } from '#/views/index/city/CityInfoPage';
import { createRootPageToolbar } from '#/views/components/RootPageToolbar';
import { createGikaiInfoPage } from '#/views/index/gikai/GikaiInfoPage';

import template from './AreaDetailPage.html';

export interface AreaDetailPageOptions {
  area: string;
}

export function createAreaDetailPage(options: AreaDetailPageOptions): Page {
  const $elem = $(template);

  const showBlue = () => {
    openPage(createCityInfoPage({}));
  };

  const showRed = () => {};

  const showGreen = () => {
    openPage(createGikaiInfoPage({}));
  };

  const showYellow = () => {
    openPage(createCatvInfoPage({}));
  };

  const $toolbar = createRootPageToolbar();
  $toolbar.find('.btn-primary').on('click', (e) => {
    showBlue();
  });
  $toolbar.find('.btn-danger').on('click', (e) => {
    showRed();
  });
  $toolbar.find('.btn-success').on('click', (e) => {
    showGreen();
  });
  $toolbar.find('.btn-warning').on('click', (e) => {
    showYellow();
  });

  $elem.find('h1').text(`${options.area} の詳細`);

  $('body').append($toolbar);
  $('body').append($elem);

  return {
    show: () => {
      $toolbar.show();
      $elem.show();
    },
    hide: () => {
      $toolbar.hide();
      $elem.hide();
    },
    unmount: () => {
      $toolbar.remove();
      $elem.remove();
    },
    onKeydown: (e) => {
      switch (e.which) {
        case VK_BLUE:
          showBlue();
          break;
        case VK_RED:
          showRed();
          break;
        case VK_GREEN:
          showGreen();
          break;
        case VK_YELLOW:
          showYellow();
          break;
        default:
          break;
      }
    },
  };
}
