import 'core-js/features/string/ends-with';
import 'core-js/features/promise';

export function addPx(a: string, b: number, min: number, max: number): string | number {
  if (a.endsWith('px')) {
    const _a = parseInt(a.slice(0, -2));
    const _v = Math.min(Math.max(_a + b, min), max);

    return _v + 'px';
  } else {
    const _a = parseInt(a);
    const _v = Math.min(Math.max(_a + b, min), max);

    return _v;
  }
}

export async function getImageSize(url: string): Promise<{ width: number; height: number }> {
  const img = new Image();
  img.src = url;

  return new Promise((resolve, reject) => {
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = () => {
      reject();
    };
  });
}
