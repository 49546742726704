import { Page, openPage } from '#/views/libs/pages';
import { createAreaSelectorPage } from '#/views/index/areas/AreaSelectorPage';
import { createCatvInfoPage } from '#/views/index/catv/CatvInfoPage';
import { createCityInfoPage } from '#/views/index/city/CityInfoPage';
import { createRootPageToolbar } from '#/views/components/RootPageToolbar';

import template from './GikaiInfoPage.html';

export interface GikaiInfoPageOptions {}

export function createGikaiInfoPage(options: GikaiInfoPageOptions): Page {
  const showBlue = () => {
    openPage(createCityInfoPage({}));
  };

  const showRed = () => {
    openPage(createAreaSelectorPage({}));
  };

  const showGreen = () => {};

  const showYellow = () => {
    openPage(createCatvInfoPage({}));
  };

  const $toolbar = createRootPageToolbar();

  $toolbar.find('.btn-primary').on('click', (e) => {
    showBlue();
  });
  $toolbar.find('.btn-danger').on('click', (e) => {
    showRed();
  });
  $toolbar.find('.btn-success').on('click', (e) => {
    showGreen();
  });
  $toolbar.find('.btn-warning').on('click', (e) => {
    showYellow();
  });

  const $main = $(template);

  $('body').append($toolbar);
  $('body').append($main);

  return {
    show: () => {
      $toolbar.show();
      $main.show();
    },
    hide: () => {
      $toolbar.hide();
      $main.hide();
    },
    unmount: () => {},
    onKeydown: (e) => {
      switch (e.which) {
        case VK_BLUE:
          showBlue();
          break;
        case VK_RED:
          showRed();
          break;
        case VK_GREEN:
          showGreen();
          break;
        case VK_YELLOW:
          showYellow();
          break;
        default:
          break;
      }
    },
  };
}
