import 'core-js/features/promise';

import { Page } from '#/views/libs/pages';
import { addPx, getImageSize } from '#/views/components/pdf/utils';

import template from './ZoomedPdfViewerPage.html';
import './ZoomedPdfViewerPage.scss';

export interface ZoomedPdfViewerPageOptions {
  imageUrl: string;
}

export function createZoomedPdfViewerPage(options: ZoomedPdfViewerPageOptions): Page {
  const $main = $(template);

  let __moveableX: boolean = false;
  let __moveableY: boolean = false;

  let __minOffsetX: number = 0;
  let __minOffsetY: number = 0;

  let __maxOffsetX: number = 0;
  let __maxOffsetY: number = 0;

  const moveUp = (): void => {
    if (!__moveableY) {
      return;
    }

    const $image = $main.find('.ZoomedPdfViewerPage__image');
    const y = $image.css('background-position-y');

    $image.css('background-position-y', addPx(y, 200, __minOffsetY, __maxOffsetY));
  };

  const moveDown = (): void => {
    if (!__moveableY) {
      return;
    }

    const $image = $main.find('.ZoomedPdfViewerPage__image');
    const y = $image.css('background-position-y');

    $image.css('background-position-y', addPx(y, -200, __minOffsetY, __maxOffsetY));
  };

  const moveLeft = (): void => {
    if (!__moveableX) {
      return;
    }

    const $image = $main.find('.ZoomedPdfViewerPage__image');
    const x = $image.css('background-position-x');

    $image.css('background-position-x', addPx(x, 200, __minOffsetX, __maxOffsetX));
  };

  const moveRight = (): void => {
    if (!__moveableX) {
      return;
    }

    const $image = $main.find('.ZoomedPdfViewerPage__image');
    const x = $image.css('background-position-x');

    $image.css('background-position-x', addPx(x, -200, __minOffsetX, __maxOffsetX));
  };

  const $image = $main.find('.ZoomedPdfViewerPage__image');
  $image
    .css('background-image', `url('${options.imageUrl}')`)
    .css('background-position-x', '0')
    .css('background-position-y', '0');

  $('body').append($main);

  (async () => {
    const { width, height } = await getImageSize(options.imageUrl);

    // this.$area.find('.ZoomedPdfViewer__thumbnail').attr('src', page.x01ImageUrl);

    await new Promise<void>((resolve) => {
      // .hidden を削除した後に一旦レンダリングさせないと領域のサイズが取得できない
      window.setTimeout(() => {
        const divWidth = $image[0].offsetWidth;
        const divHeight = $image[0].offsetHeight;

        __moveableX = width > divWidth;
        if (__moveableX) {
          __minOffsetX = -(width - divWidth + divWidth / 4);
          __maxOffsetX = divWidth / 4;
        } else {
          const positionX = (divWidth - width) / 2;
          $image.css('background-position-x', `${positionX}px`);
        }

        __moveableY = height > divHeight;
        if (__moveableY) {
          __minOffsetY = -(height - divHeight + divHeight / 4);
          __maxOffsetY = divHeight / 4;
        } else {
          const positionY = (divHeight - height) / 2;
          $image.css('background-position-y', `${positionY}px`);
        }

        resolve();
      }, 0);
    });
  })();

  return {
    show: () => {
      $main.show();
    },
    hide: () => {
      $main.hide();
    },
    unmount: () => {},
    onKeydown: (e) => {
      switch (e.which) {
        case VK_BLUE:
          break;
        case VK_RED:
          break;
        case VK_GREEN:
          break;
        case VK_YELLOW:
          break;
        case VK_UP:
          moveUp();
          break;
        case VK_DOWN:
          moveDown();
          break;
        case VK_LEFT:
          moveLeft();
          break;
        case VK_RIGHT:
          moveRight();
          break;
        default:
          break;
      }
    },
  };
}
