import 'core-js/features/promise';

import { useMpegDash } from '#/utils/browser';
import { Deferred } from '@phaier/util/lib/Deferred';

export interface WebKitHTMLVideoElementEventMap extends HTMLVideoElementEventMap {
  webkitendfullscreen: Event;
}

export interface WebKitHTMLVideoElement extends HTMLVideoElement {
  webkitRequestFullscreen(): Promise<void>;

  addEventListener<K extends keyof WebKitHTMLVideoElementEventMap>(
    type: K,
    listener: (this: WebKitHTMLVideoElement, ev: WebKitHTMLVideoElementEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener<K extends keyof WebKitHTMLVideoElementEventMap>(
    type: K,
    listener: (this: WebKitHTMLVideoElement, ev: WebKitHTMLVideoElementEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;
}

export async function requestFullScreen(elem: HTMLVideoElement): Promise<void> {
  if (elem.requestFullscreen) {
    try {
      await elem.requestFullscreen();
    } catch (ex) {
      console.error(ex);
    }

    return;
  }

  const e = elem as WebKitHTMLVideoElement;
  if (e.webkitRequestFullscreen) {
    try {
      await e.webkitRequestFullscreen();
    } catch (ex) {
      console.error(ex);
    }

    return;
  }
}

export async function exitFullScreen(): Promise<void> {
  if (!document.fullscreenElement) {
    return;
  }

  await document.exitFullscreen();
}

export class Video {
  private __useMpegDash: boolean;
  private __player: DashTVPlayer | undefined;
  private __playing: boolean = false;

  private __onEnded: Deferred<void>;

  constructor(private readonly __element: HTMLVideoElement) {
    this.__useMpegDash = useMpegDash;
    this.__onEnded = new Deferred<void>();
  }

  private __handleEnded = async (ev: Event) => {
    console.log('ended', this, ev);

    await this.__stop();
  };

  private __handleFullScreenChange = (ev: Event) => {
    console.log('fullscreenchange', ev);

    if (!document.fullscreenElement) {
      this.__stop();
    }
  };

  private __handleFullScreenError = (ev: Event) => {
    console.error('fullscreenerror', ev);
  };

  private __handleWebKitEndFullScreen = (ev: Event) => {
    console.log('webkitendfullscreen', ev);

    this.__stop();
  };

  private async __play(urls: { dash: string; hls: string }): Promise<void> {
    if (this.__useMpegDash) {
      this.__player = new DashTVPlayer(this.__element);
      const src: UrlType = {
        type: 'url',
        source: urls.dash,
        params: {
          DEFAULT_MIN_BUFFER_TIME: 2,
          FORCE_DEFAULT_MBT: false,
          MSE_APPEND_ENABLE_THRESHOLD: 5,
          BUFFER_PREFETCH_THRESHOLD: 15,
          LOADING_REQUEST_THRESHOLD: 2,
          DEFAULT_MANIFEST_REFRESH_DELAY: 10,
          //DEFAULT_PRESENTATION_DELAY: 2,
          //ADJUST_TIMESTAMP_OFFSET : false,
          //START_FROM_MPDTOP_FORLIVE: true,
          //SET_1STSEG_TIME_ZERO: false,
          //MIN_SEGSIZE_FORBASE: 2,
          //EXTRACT_ALL_IDR_IN_MOOF: true,
          //LISTEN_TO_CANPLAY_AFTER_SEEK: true,
          USE_FETCH: true,
          DELETE_PAST_DASHEVENT: true,
          DELETE_UNNECESSARY_BOX: true,
          //UNUSE_AUDIO: true,
          //ULL_MODE: true,
          BDAT_INSERT_MODE: false,

          // ココからカスタム
          SKIP_GAP_AT_HOB: true,
        },
      };

      this.__player.setAutoPlay(true);
      this.__player.attachSource(src, NaN, NaN);

      this.__element.addEventListener('ended', this.__handleEnded);
      this.__element.addEventListener('fullscreenchange', this.__handleFullScreenChange);
      this.__element.addEventListener('fullscreenerror', this.__handleFullScreenError);

      await requestFullScreen(this.__element);
    } else {
      this.__element.setAttribute('src', urls.hls);

      this.__element.load();
      this.__element.addEventListener('canplay', async (ev) => {
        this.__element.play();

        await requestFullScreen(this.__element);
      });

      this.__element.addEventListener('webkitendfullscreen', this.__handleWebKitEndFullScreen);
    }
  }

  private async __stop(): Promise<void> {
    if (!this.__playing) {
      return;
    }

    this.__playing = false;

    if (useMpegDash) {
      if (this.__player) {
        this.__player.setPause(true);
        this.__player.end();
        this.__player.reset();
        this.__player = undefined;

        this.__element.removeEventListener('ended', this.__handleEnded);
        this.__element.removeEventListener('fullscreenchange', this.__handleFullScreenChange);
        this.__element.removeEventListener('fullscreenerror', this.__handleFullScreenError);
      }
    } else {
      this.__element.pause();
      this.__element.removeEventListener('webkitendfullscreen', this.__handleWebKitEndFullScreen);
      this.__element.removeAttribute('src');
    }

    await exitFullScreen();

    this.__onEnded.resolve();
  }

  public play(urls: { dash: string; hls: string }): Promise<void> {
    if (this.__playing) {
      return Promise.resolve();
    }

    this.__playing = true;
    this.__onEnded = new Deferred<void>();

    return new Promise<void>((resolve, reject) => {
      this.__play(urls);

      this.__onEnded.promise().then(
        async () => {
          await this.__stop();

          resolve();
        },
        (reason) => {
          reject(reason);
        }
      );
    });
  }

  public async stop(): Promise<void> {
    if (!this.__playing) {
      return;
    }

    this.__onEnded.resolve();

    // await this.__stop();
  }
}
