import { Page, openPage } from '#/views/libs/pages';
import { Doc } from '#/models/docs/Doc';
import { createAreaSelectorPage } from '#/views/index/areas/AreaSelectorPage';
import { createCityInfoPage } from '#/views/index/city/CityInfoPage';
import { createRootPageToolbar } from '#/views/components/RootPageToolbar';
import { createPdfViewerPage } from '#/views/components/pdf/PdfViewerPage';
import { createMultiLineThumbnailSelector } from '#/views/components/thumbnails/MultiLineThumbnailSelector';
import { createGikaiInfoPage } from '#/views/index/gikai/GikaiInfoPage';

import template from './MyudeDetailPage.html';

export interface MyudeDetailPageOptions {}

export function createMyudeDetailPage(options: MyudeDetailPageOptions): Page {
  const showBlue = () => {
    openPage(createCityInfoPage({}));
  };

  const showRed = () => {
    openPage(createAreaSelectorPage({}));
  };

  const showGreen = () => {
    openPage(createGikaiInfoPage({}));
  };

  const showYellow = () => {};

  const $toolbar = createRootPageToolbar();

  $toolbar.find('.btn-primary').on('click', (e) => {
    showBlue();
  });
  $toolbar.find('.btn-danger').on('click', (e) => {
    showRed();
  });
  $toolbar.find('.btn-success').on('click', (e) => {
    showGreen();
  });
  $toolbar.find('.btn-warning').on('click', (e) => {
    showYellow();
  });

  const $main = $(template);

  // みゅうで！
  $main.find('.col-md-12').append($(`<h2>みゅうで！</h2>`));
  $main.find('.col-md-12').append(
    ...createMultiLineThumbnailSelector(Doc.myude.map(Doc.toPdfDocument), {
      aspectRatio: 'auto',
      setThumbnail: ($thumbnail, item) => {
        $thumbnail.find('img').attr('src', item.thumbnailUrl);
        $thumbnail.find('h3').text(item.title);
      },
      onFocused(_, item) {},
      onClicked(_, item) {
        openPage(createPdfViewerPage({ pdfDocument: item }));
      },
      colClass: 'col-md-2',
      cols: 6,
    })
  );

  $('body').append($toolbar);
  $('body').append($main);

  return {
    show: () => {
      $toolbar.show();
      $main.show();
    },
    hide: () => {
      $toolbar.hide();
      $main.hide();
    },
    unmount: () => {},
    onKeydown: (e) => {
      switch (e.which) {
        case VK_BLUE:
          showBlue();
          break;
        case VK_RED:
          showRed();
          break;
        case VK_GREEN:
          showGreen();
          break;
        case VK_YELLOW:
          showYellow();
          break;
        default:
          break;
      }
    },
  };
}
