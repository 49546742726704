export function onLoadImage(elem: HTMLImageElement): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const onLoad = () => {
      resolve();
      elem.removeEventListener('load', onLoad);
      elem.removeEventListener('error', onError);
    };

    const onError = (e: ErrorEvent) => {
      reject(e);
      elem.removeEventListener('load', onLoad);
      elem.removeEventListener('error', onError);
    };

    if (elem.complete) {
      resolve();
      return;
    }

    elem.addEventListener('load', onLoad);
    elem.addEventListener('error', onError);
  });
}
