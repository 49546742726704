import { toRows } from '#/views/utils/grids';

import template from './MultiLineThumbnailSelector.html';
import './MultiLineThumbnailSelector.scss';

const $buttonOriginal = $(template);

export interface MultiLineThumbnailSelectorOptions<T> {
  readonly aspectRatio: '16by9' | '4by3' | 'auto';
  setThumbnail($thumbnail: JQuery, item: T): void;
  onFocused($thumbnail: JQuery, item: T): void;
  onClicked($thumbnail: JQuery, item: T): void;
  colClass?: string;
  cols?: number;
}

function initThumbnail<T>(options: MultiLineThumbnailSelectorOptions<T>): JQuery {
  const $button = $buttonOriginal.clone();

  if (options.aspectRatio === '16by9') {
    $button.find('.embed-responsive').addClass('embed-responsive-16by9');
  } else if (options.aspectRatio === '4by3') {
    $button.find('.embed-responsive').addClass('embed-responsive-4by3');
  } else {
    $button.find('.embed-responsive').after($(`<img alt="..." />`));
    $button.find('.embed-responsive').remove();
  }

  return $button;
}

export function createMultiLineThumbnailSelector<T>(
  items: ReadonlyArray<T>,
  options: MultiLineThumbnailSelectorOptions<T>
): ReadonlyArray<JQuery> {
  const colClass = options.colClass ?? 'col-md-3';
  const cols = options.cols ?? 4;

  const $thumbnailOriginal = initThumbnail(options);

  const $cols = items.map((item) => {
    const $thumbnail = $thumbnailOriginal.clone();
    options.setThumbnail($thumbnail, item);

    $thumbnail.on('click', (e) => {
      e.preventDefault();

      options.onClicked($thumbnail, item);
    });

    $thumbnail.on('focus', (e) => {
      options.onFocused($thumbnail, item);
    });

    return $thumbnail;
  });

  return toRows($cols, colClass, cols);
}
