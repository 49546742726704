import { Page, openPage } from '#/views/libs/pages';
import { PdfDocument } from '#/views/components/pdf/PdfDocument';
import { createZoomedPdfViewerPage } from '#/views/components/pdf/ZoomedPdfViewerPage';

import template from './PdfViewerPage.html';
import './PdfViewerPage.scss';

export interface PdfViewerPageOptions {
  readonly pdfDocument: PdfDocument;
}

export function createPdfViewerPage(options: PdfViewerPageOptions): Page {
  const $main = $(template);

  const __document = options.pdfDocument;
  let __page: number = 0;

  const setImage = (): void => {
    const page = __document.pages[__page];

    $main.find('.PdfViewerPage__image').attr('src', page.x01ImageUrl);
  };

  const zoom = async (): Promise<void> => {
    const page = __document.pages[__page];

    openPage(createZoomedPdfViewerPage({ imageUrl: page.x02ImageUrl }));
  };

  const zoomX4 = async (): Promise<void> => {
    const page = __document.pages[__page];

    openPage(createZoomedPdfViewerPage({ imageUrl: page.x04ImageUrl }));
  };

  const movePrev = (): void => {
    if (__page <= 0) {
      return;
    }

    __page--;
    setImage();
  };

  const moveNext = (): void => {
    if (__page >= __document.pages.length - 1) {
      return;
    }

    __page++;
    setImage();
  };

  $main.find('.PdfViewerPage__thumbnail').attr('src', __document.thumbnailUrl);

  $main.find('.PdfViewerPage__movePrev').on('click', () => {
    movePrev();
  });
  $main.find('.PdfViewerPage__moveNext').on('click', () => {
    moveNext();
  });
  $main.find('.PdfViewerPage__toolbarZoom').on('click', () => {
    zoom();
  });
  $main.find('.PdfViewerPage__toolbarZoomX4').on('click', () => {
    zoomX4();
  });

  setImage();

  $('body').append($main);

  return {
    show: () => {
      $main.show();
    },
    hide: () => {
      $main.hide();
    },
    unmount: () => {},
    onKeydown: (e) => {
      switch (e.which) {
        case VK_BLUE:
          movePrev();
          break;
        case VK_RED:
          zoom();
          break;
        case VK_GREEN:
          zoomX4();
          break;
        case VK_YELLOW:
          moveNext();
          break;
        case VK_LEFT:
          movePrev();
          break;
        case VK_RIGHT:
          moveNext();
          break;
        case VK_ENTER:
          zoom();
          break;
        default:
          break;
      }
    },
  };
}
