export function toRows(items: ReadonlyArray<JQuery>, col: string, cols: number): ReadonlyArray<JQuery> {
  const $rows: JQuery[] = [];

  let $cols: JQuery[] = [];

  for (let i = 0; i < items.length; i++) {
    const $col = $('<div />');
    $col.addClass(col);
    $col.append(items[i]);

    if ($cols.length === cols) {
      const $row = $('<div class="row" />');
      $row.append(...$cols);
      $rows.push($row);
      $cols = [$col];
    } else {
      $cols.push($col);
    }
  }

  if ($cols.length) {
    const $row = $('<div class="row" />');
    $row.append(...$cols);
    $rows.push($row);
  }

  return $rows;
}
