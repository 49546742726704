import { RiverInfo } from '#/models/rivers/RiverInfo';
import { inHybridcast } from '#/utils/hybridcast';
import { onLoadImage } from '#/utils/images';
import { createOneLineThumbnailSelector } from '#/views/components/thumbnails/OneLineThumbnailSelector';

import template from './RiverInfoViewer.html';
import './RiverInfoViewer.scss';

const THUMBNAIL_URL_ATTR_NAME = 'data-thumbnail-url';

export interface RiverInfoViewerOptions {}

export function createRiverInfoViewer(
  rivers: ReadonlyArray<RiverInfo>,
  options: RiverInfoViewerOptions
): [JQuery, () => void] {
  const $viewer = $(template);

  const $selector = createOneLineThumbnailSelector(rivers, {
    aspectRatio: '16by9',
    setThumbnail: ($thumbnail, item) => {
      $thumbnail
        .find('img')
        .attr('src', item.thumbnailUrl + '?r=' + new Date().getTime())
        .attr(THUMBNAIL_URL_ATTR_NAME, item.thumbnailUrl);
      $thumbnail.find('h3').text(item.name);
    },
    onFocused: ($thumbnail, item) => {
      if (inHybridcast()) {
        setDetail(item);
      }
    },
    onClicked: ($thumbnail, item) => {
      setDetail(item);
    },
  });
  $viewer.append($selector);

  const setDetail = (item: RiverInfo) => {
    const $map = $viewer.find('img.RiverInfoViewer__map');

    $map.attr('src', `./image/rivers/maps/${item.mapFileName}`);

    const $thumbnail = $viewer.find('img.RiverInfoViewer__thumbnail');

    $thumbnail
      .attr('src', item.thumbnailUrl + '?r=' + new Date().getTime())
      .attr(THUMBNAIL_URL_ATTR_NAME, item.thumbnailUrl);

    $viewer.find('h3.RiverInfoViewer__riverName').text(`撮影場所 : ${item.name}`);
  };

  const handler = window.setInterval(() => {
    $viewer.find('img').each((i, e) => {
      const $e = $(e);
      const src = $e.attr(THUMBNAIL_URL_ATTR_NAME);
      if (src) {
        $e.attr('src', src + '?r=' + new Date().getTime());
      }
    });
  }, 1000 * 10);

  setDetail(rivers[0]);

  window.setTimeout(async () => {
    const $map = $viewer.find('img.RiverInfoViewer__map');

    try {
      await onLoadImage(<HTMLImageElement>$map[0]);

      const height = $viewer.find('.RiverInfoViewer__mapArea').height()!;
      // $viewer.find('.RiverInfoViewer__thumbnailArea').height(height);
    } catch (e) {
      console.error(e);
    }
  }, 0);

  return [
    $viewer,
    () => {
      window.clearInterval(handler);
    },
  ];
}
