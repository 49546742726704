/**
 * 河川水位情報
 */

export interface RiverInfo {
  readonly id: number;
  readonly name: string;

  readonly thumbnailUrl: string;

  readonly mapFileName: string;
}

export namespace RiverInfo {
  export const rivers: ReadonlyArray<RiverInfo> = [
    {
      id: 1,
      name: '大川町駒鳴',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera2.jpg',
      mapFileName: '01_okawachi_cam_map.png',
    },
    {
      id: 2,
      name: '大川町大川野排水機場',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera1.jpg',
      mapFileName: '02_okawano_haisui_map.png',
    },
    {
      id: 3,
      name: '大川町大川野川西橋',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera3.jpg',
      mapFileName: '03_kawanishi_bridge_map.png',
    },
    {
      id: 4,
      name: '松浦町桃ノ川橋',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera4.jpg',
      mapFileName: '04_momonokawa_bridge_map.png',
    },
    {
      id: 5,
      name: '伊万里湾',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera8.jpg',
      mapFileName: '05_imari_bay_map.png',
    },
    {
      id: 6,
      name: '伊万里川 岩栗',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera7.jpg',
      mapFileName: '06_imari_river_iwakuri_map.png',
    },
    {
      id: 7,
      name: '新田川 黄金橋',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera6.jpg',
      mapFileName: '07_arata_river_kogane_map.png',
    },
    {
      id: 8,
      name: '有田川 長井手',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera5.jpg',
      mapFileName: '08_arita_river_nagaide_map.png',
    },
    {
      id: 9,
      name: '二里大橋付近',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera10.jpg',
      mapFileName: '09_nirioohashi_hukin_map.png',
    },
    {
      id: 10,
      name: '波多津町馬蛤潟',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera15.jpg',
      mapFileName: '10_hatatsu_mategata_map.png',
    },
    {
      id: 11,
      name: '南波多町水留 清水橋',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera14.jpg',
      mapFileName: '11_minamitaha_seisuibashi_map.png',
    },
    {
      id: 12,
      name: '大川内山',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera11.jpg',
      mapFileName: '12_ookawauchi_map.png',
    },
    {
      id: 13,
      name: '有田川 川東橋付近',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera16.jpg',
      mapFileName: '13_arata_river_kawahigashi_map.png',
    },
    {
      id: 14,
      name: '伊万里川 松島橋付近',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera18.jpg',
      mapFileName: '14_imari_river_matsushima_map.png',
    },
    {
      id: 15,
      name: '新田川河口',
      thumbnailUrl: 'https://i-catv.jp/live_camera/camera19.jpg',
      mapFileName: '15_arata_river_kakou_map.png',
    },
  ];

  const updateThumbnailInterval = 20 * 1000;
  let selected: RiverInfo = rivers[0];

  const $thumbnailOriginal = $(`
  <button type="button" class="cts-river-info-thumbnail">
    <div class="embed-responsive embed-responsive-16by9">
      <img alt="..." class="embed-responsive-item" src="./image/thumbnails/empty.png" />
    </div>
    <div class="cts-river-info-thumbnail__caption">
      <h3></h3>
    </div>
  </button>
`);

  export function createRiverInfoViewer(item: RiverInfo): JQuery {
    const $thumbnail = $thumbnailOriginal.clone();

    $thumbnail.find('img').attr('src', item.thumbnailUrl + '?r=' + new Date().getTime());
    $thumbnail.find('h3').text(item.name);

    $thumbnail.on('click', (e) => {
      e.preventDefault();

      selectRiverInfo(item);
    });

    $thumbnail.on('focus', (e) => {
      selectRiverInfo(item);
    });

    window.setInterval(() => {
      const now = new Date();
      $thumbnail.find('img').attr('src', item.thumbnailUrl + '?r=' + now.getTime());
    }, updateThumbnailInterval);

    return $thumbnail;
  }

  export function selectRiverInfo(info: RiverInfo): void {
    const $map = $('#cts-river-info-map');

    // $map.find('h3').text(info.name);
    $map.find('img').attr('src', `./image/rivers/maps/${info.mapFileName}`);

    const $thumbnail = $('#cts-river-info-thumbnail');

    $thumbnail.find('img').attr('src', info.thumbnailUrl + '?r=' + new Date().getTime());
    $thumbnail.find('h3').text(info.name);

    selected = info;
  }

  export function updateThumbnail(): void {
    const now = new Date();
    const $thumbnail = $('#cts-river-info-thumbnail');

    $thumbnail.find('img').attr('src', selected.thumbnailUrl + '?r=' + now.getTime());
  }

  const $selectorOriginal = $(`<li><a href="#"></a></li>`);

  export function createRiverInfoSelector(info: RiverInfo): JQuery {
    const $selector = $selectorOriginal.clone();

    $selector.find('a').text(info.name);
    $selector.find('a').on('click', (e) => {
      e.preventDefault();

      selectRiverInfo(info);
    });

    $selector.find('a').on('focus', (e) => {
      selectRiverInfo(info);
    });

    return $selector;
  }

  export function init(): void {
    /*
    const $selector = $('#cts-river-info-thumbnails-selector');

    const $ul = $selector.find('ul.pagination');
    $ul.empty();

    for (let i = 0; i < rivers.length; i++) {
      const info = rivers[i];
      const $li = createRiverInfoSelector(info);

      $ul.append($li);
    }
    */

    const $row = $('#cts-river-info-thumbnails');
    $row.empty();

    for (let i = 0; i < rivers.length; i++) {
      const info = rivers[i];
      const $thumbnail = createRiverInfoViewer(info);
      const $col = $('<div class="col-md-2"></div>');

      $col.append($thumbnail);
      $row.append($col);
    }

    selectRiverInfo(rivers[0]);
    window.setInterval(updateThumbnail, updateThumbnailInterval);
  }
}
